body {
  margin: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fffbff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#home-grid {
  background: rgb(255,251,255);
  background: linear-gradient(0deg, rgba(255,251,255,1) 0%, rgba(255,251,255,1) 60%, rgba(255,240,240,1) 100%);
  min-height: 100vh;
}

.ember-font {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
}

.ember-font-fancy {
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "SOFT" 0,
    "WONK" 0;
}